/**
 * Do not edit directly
 * Generated by StyleDictionary
 * Prism Design Tokens version: 2.0.0.120
 */


@import url("_common.css");
[prism-icon="sh-id-nobox"],
[prism-icon-family="md"] [prism-icon="sh-id-nobox"],
[prism-icon-family="md"][prism-icon="sh-id-nobox"] {
    --mask-image: url("data:image/svg+xml;utf8,%3C!--%20Generated%20by%20IcoMoon.io%20--%3E%0A%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%3Ctitle%3Esh-id-nobox%3C%2Ftitle%3E%0A%3Cpath%20d%3D%22M7.4%2017.688h-2.738v-11.375h2.738z%22%3E%3C%2Fpath%3E%0A%3Cpath%20d%3D%22M10.9%2017.688v-11.375h3.742c0.004%200%200.008-0%200.013-0%200.974%200%201.887%200.26%202.674%200.714l-0.026-0.014c0.779%200.453%201.405%201.094%201.827%201.862l0.013%200.025c0.441%200.797%200.7%201.748%200.7%202.759%200%200.002%200%200.004%200%200.006v-0%200.576c0%200.029%200%200.064%200%200.1%200%200.003%200%200.006%200%200.010%200%200.997-0.25%201.935-0.691%202.755l0.015-0.031c-0.431%200.792-1.050%201.433-1.8%201.878l-0.022%200.012c-0.758%200.452-1.672%200.719-2.649%200.719-0.004%200-0.007%200-0.011%200h-3.785zM13.645%208.43v7.148h0.955c0.034%200%200.074%200%200.114%200%200%200%200.001%200%200.001%200%200.688%200%201.301-0.326%201.69-0.833l0.004-0.005c0.406-0.623%200.647-1.385%200.647-2.204%200-0.004%200-0.007-0-0.011v0.001c0-0.1%200-0.2-0.011-0.3v-0.475c0.006-0.085%200.010-0.185%200.010-0.285%200-0.002%200-0.004%200-0.005%200-0.822-0.244-1.587-0.664-2.227l0.009%200.015c-0.401-0.503-1.013-0.823-1.7-0.824h-0c-0.046%200-0.091%200-0.136%200z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");
    mask-image: var(--mask-image);
    -webkit-mask-image: var(--mask-image);
}
